import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import Divider from '~/components/atom/Divider';
import { FlowAction } from '~/graphql/types';

import { ClientFlowActionWait } from '~/graphql/types.client';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import ConditionList from '../ConditionList';
import WaitStatistics from './components/WaitStatistics';
import interactions from '~/components/page/Automation/v2/state/interactions';
import metadata from '~/components/page/Automation/v2/state/metadata';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import { FlowV2_Update_Action, FlowV2_Update_Metadata } from '~/graphql/types';
import RadioButton from '~/components/molecule/RadioButton';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import TEST_ID from './index.testid';
import { Body } from '~/components/atom/Typography';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = Omit<
  CardProps<ClientFlowActionWait>,
  'heading' | 'actionType'
> & {
  conditions: Array<any>;
  activePeriod?: number;
  leads: number;
  linkTo: string;
};

export const text = {
  confirmModalHeader: 'Wat moeten we doen met de contacten in deze stap?',
  confirmModalDescription:
    'Er lijken contacten in deze stap van de flow te zitten. Wil je deze contacten doorsturen naar de volgende stap in de flow of de flow stopzetten voor deze contacten?',
  continue: 'Doorsturen naar de volgende stap in de flow',
  drop: 'De flow stoppen voor deze contacten',
  confirm: 'Bevestigen',
};

const WaitForCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, ...props }) => {
    const [activeInteraction, setActiveInteraction] =
      useRecoilState(interactions);

    const [updateAction, setUpdateAction] = useState<FlowV2_Update_Action>(
      FlowV2_Update_Action.Drop,
    );

    const setFlowMetadata = useSetRecoilState(metadata);
    const maps = useRelativeMaps({ actionId: props.data.action.id });

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.Wait}
          heading={getCardHeading(FlowAction.Wait)}
          selected={selected}
          disabled={disabled}
        >
          <ConditionsContainer>
            <ConditionList
              actionType={FlowAction.Start}
              condition={props.data.action.condition}
              maps={maps}
              actionId={props.data.action.id}
            />
          </ConditionsContainer>
          {props.data.action.Statistics && (
            <>
              <Divider />
              <WaitStatistics
                leads={
                  props.data.action.Statistics.waitingFlowInstancesTotal ?? 0
                }
                linkTo={'/-/contacts'}
              />
            </>
          )}
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />

        {activeInteraction?.type === 'setMetadataForWaitAction' && (
          <Overlay onClose={() => setActiveInteraction(null)}>
            <Dialog
              dataTestId={TEST_ID.DIALOG + activeInteraction.action.id}
              header={text.confirmModalHeader}
              body={
                <JustificationContainer direction="column" gap="m" width="100%">
                  <Body>{text.confirmModalDescription}</Body>
                  {/* <RadioButton
                    checked={updateAction === FlowV2_Update_Action.Continue}
                    onChange={() =>
                      setUpdateAction(FlowV2_Update_Action.Continue)
                    }
                    label={text.continue}
                    dataTestId={FlowV2_Update_Action.Continue}
                  /> */}
                  <RadioButton
                    checked={updateAction === FlowV2_Update_Action.Drop}
                    onChange={() => setUpdateAction(FlowV2_Update_Action.Drop)}
                    label={text.drop}
                    dataTestId={FlowV2_Update_Action.Drop}
                  />
                </JustificationContainer>
              }
              buttons={[
                {
                  dataTestId: 'modal-confirm-button',
                  label: text.confirm,
                  onClick: () => {
                    setFlowMetadata(prev => {
                      const filteredPrevious = prev.filter(
                        act =>
                          act.flowBlueprintActionId !==
                          activeInteraction.action.id,
                      );

                      return [
                        ...filteredPrevious,
                        {
                          flowBlueprintActionId: activeInteraction.action.id,
                          action: updateAction,
                        } as FlowV2_Update_Metadata,
                      ];
                    });

                    activeInteraction.onConfirm();
                    setActiveInteraction(null);
                  },
                },
              ]}
            />
          </Overlay>
        )}
      </>
    );
  },
);

const ConditionsContainer = styled.div<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default WaitForCard;
