import { atom, selector, selectorFamily } from 'recoil';
import { IBuilderContext } from '../components/Builder/context';

import { ClientFlowAction } from '~/graphql/types.client';
import { getActionTemplate } from '../components/Builder/constants/actionTemplates';
import { FlowAction } from '~/graphql/types';
import getLabelledActions from '../util/getLabelledActions';
import { keys, equals, pick } from 'ramda';
import { initialFlowState } from '.';

/*
 * lastAddedActionId must be an atom instead of a selector because we need
 * to compare the previous actions state with the new actions state to determine which action
 * was just added. A selector can only access the current state, not the
 * previous state that existed before an update.
 */
export const lastAddedActionId = atom<string | null>({
  key: 'lastAddedFlowActionId',
  default: null,
});

export const flowActions = atom<Array<ClientFlowAction>>({
  key: 'flowActions',
  default: [],
});

export const actionById = selectorFamily({
  key: 'actionById',
  get:
    (actionId: string | null) =>
    ({ get }): ClientFlowAction | undefined => {
      if (actionId === null) return;

      const actions = get(flowActions);
      return actions.find(({ id }) => id === actionId);
    },
});

export const actionsSelector = selectorFamily({
  key: 'actionsSelector',
  get:
    ({
      accountId,
      flowBlueprintId,
    }: {
      accountId: IBuilderContext['accountId'];
      flowBlueprintId: IBuilderContext['flowBlueprintId'];
    }) =>
    ({ get }) => {
      const actions = get(flowActions);
      if (actions.length === 0) {
        const generatedStartAction = getActionTemplate({
          flowBlueprintId,
          actionType: FlowAction.Start,
          accountId,
        });
        // This expression is only there to keep Typescript happy.
        // There will always be start action from this call
        return generatedStartAction !== null ? [generatedStartAction] : [];
      }

      return getLabelledActions(actions);
    },

  set:
    (_builderContext: IBuilderContext) =>
    ({ set, get }, newValue: Array<ClientFlowAction>) => {
      const prevActions = get(flowActions);
      const updatedActions = newValue;

      // Compare the prev state to current state and return the last added action
      const lastAddedAction = updatedActions.find(
        currentAction =>
          !prevActions.some(prevAction => prevAction.id === currentAction.id),
      );
      set(lastAddedActionId, lastAddedAction?.id);
      set(flowActions, updatedActions);
    },
});

/** Value is true if it has changed */
export type FlowActionChanges = {
  /** Keeps track of updated action fields in Action forms  */
  actions: { [key: string]: boolean };

  /** Keeps track of all actions that are added/deleted */
  actionCount: boolean;
};

export const flowActionChanges = selector<FlowActionChanges>({
  key: 'flowActionChanges',
  get: ({ get }) => {
    const actions = get(flowActions);
    const initialActions = get(initialFlowState)?.actions;

    if (initialActions && initialActions.length > 0) {
      const changedActions = actions.filter(action => {
        const initialAction = initialActions.find(({ id }) => id === action.id);
        if (!initialAction) return false;

        // Only compare the common properties
        const actionKeys = keys(initialAction);
        const updatedActionWithCommonFields = pick(actionKeys, action);
        const hasChanged = !equals(
          initialAction,
          updatedActionWithCommonFields,
        );

        return hasChanged;
      });

      const flowChanges = {
        actions: {},
        actionCount: false,
      };

      if (changedActions.length > 0) {
        changedActions.forEach(({ id }) => {
          flowChanges.actions[id] = true;
        });
      }

      // This is needed when the action with no children and no IfElse parent is added or deleted
      if (initialActions.length !== actions.length) {
        flowChanges.actionCount = true;
      }

      return flowChanges;
    }

    return {
      actionCount: false,
      actions: {},
    };
  },
});
