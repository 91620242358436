import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionRealworksSendContact } from '~/graphql/types.client';

/**
 *
 * @param {ClientFlowActionRealworksSendContact} action - RealworksSendContact client action
 * keywords:
 */
const serializeRealworksSendContactAction = (
  action: ClientFlowActionRealworksSendContact,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, tokenContainerId } =
    action;

  return {
    Realworks: {
      SendContact: {
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        // Description field is being removed from BE, so for now we send empty object, after BE is updated we can remove this
        description: { mappings: [] },
        tokenContainerId,
      },
    },
  };
};
export default serializeRealworksSendContactAction;
