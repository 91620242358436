import { selectorFamily } from 'recoil';
import { HandledFlowAction } from '~/graphql/types.client';
import type { IBuilderContext } from '../components/Builder/context';
import { flowActions } from './actions';
import { pluck } from 'ramda';
import { deepLinks } from './deepLinks';
import generateIssuesForActions from '../components/Builder/utils/generateIssuesForActions';

type BaseIssue = {
  level: 'error' | 'warning' | null;
  actionId: string | null;
  actionType: HandledFlowAction | null;
  message: string;
};

export type ConditionIssue = BaseIssue & {
  type: 'ConditionIssue';
  id: string;
  level: 'warning' | 'error';
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  conditionIdx?: number;
  argumentIdx?: number;
};

export type ValidationIssue = BaseIssue & {
  type: 'ValidationIssue';
  key: string;
  level: 'warning' | 'error';
};

export type BadVariableIssue = BaseIssue & {
  type: 'BadVariableIssue';
  level: 'error';
};

export type BrokenFileIssue = BaseIssue & {
  type: 'BrokenFileIssue';
  level: 'error';
};

export type MissingInlineImageIssue = BaseIssue & {
  type: 'MissingInlineImageIssue';
  level: 'error';
};

export type UnavailableActionIssue = BaseIssue & {
  type: 'UnavailableActionIssue';
  level: 'error';
  pathToApp: string | null;
};

export type ZapierTriggerOutOfBoundsIssue = BaseIssue & {
  type: 'ZapierTriggerOutOfBounds';
  level: 'error';
};

export type SettingsIssue = {
  type: 'SettingsIssue';
  level: 'error' | 'warning';
  message: string;
};

export type Issue =
  | ConditionIssue
  | ValidationIssue
  | UnavailableActionIssue
  | BadVariableIssue
  | BrokenFileIssue
  | MissingInlineImageIssue
  | ZapierTriggerOutOfBoundsIssue
  | SettingsIssue;

export type IssueMap = Map<string, Array<Issue>>;

const flowIssues = selectorFamily({
  key: 'flowIssues',
  get:
    (builderContext: IBuilderContext) =>
    ({ get }) => {
      const actions = get(flowActions);
      const deepLinksMap = get(deepLinks);

      const {
        availableActions,
        opts: { subjectMap, conditionMap, subjectToConditionMap, directoryMap },
        instanceMap,
        subjects,
        instances,
        primitiveInputMap,
        zapierTriggers,
        offices,
        users,
      } = builderContext;
      const issueOptions = zapierTriggers
        ? {
            offices,
            users,
            zapier: {
              triggers: pluck('id', zapierTriggers),
            },
          }
        : { offices, users };

      const issues = generateIssuesForActions({
        actions,
        maps: {
          conditionMap,
          directoryMap,
          instanceMap,
          primitiveInputMap,
          subjectMap,
          subjectToConditionMap,
          instances,
          subjects,
        },
        opts: issueOptions,
        availableActions,
        deepLinksMap,
      });

      return issues;
    },
});

export default flowIssues;
