import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import IframeResizer, { type IFrameObject } from 'iframe-resizer-react';

import formatNode from '../../../ScreenPreview/utils/formatNode';
import type { ScreenNode } from '../../../ScreenPreview/utils/formatNode/types';
import type {
  FormBuilder_Locale,
  FormBuilder_OptionList,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import useDebouncedState from '~/hooks/useDebouncedState';
import getComponentPreviewInitialHeight from './utils';

export type BlockMessage = {
  type: 'block';
  payload: ScreenNode['blocks'][number];
};

export type LocaleChangeMessage = {
  type: 'localeChange';
  payload: {
    locale: FormBuilder_Locale;
  };
};

const APP_SHELL_COMPONENT_PREVIEW_URL =
  'https://d20r886yjkdo9k.cloudfront.net/component-preview';

const EmbeddedPreview: React.FC<{
  block: FormBuilder_ScreenNode_Block;
  optionLists: Array<FormBuilder_OptionList>;
  currentLocale: FormBuilder_Locale;
  onReady?: () => void;
}> = ({ block, optionLists, currentLocale, onReady }) => {
  const [debouncedHeight, setDebouncedHeight] = useDebouncedState(
    getComponentPreviewInitialHeight(block.__typename),
    {
      delay: 200,
    },
  );
  const frameRef = useRef<IFrameObject | null>(null);
  const frameId = useMemo(() => `preview-frame-${block.key}`, [block.key]);

  const onEmitBlockDetails = useCallback(
    (_block: FormBuilder_ScreenNode_Block) => {
      const previewProps = formatNode(
        {
          __typename: 'FormBuilder_ScreenNode',
          blocks: [_block],
          id: 'fake-id',
          name: 'fake-name',
          defaultNext: null,
        },
        optionLists,
      );

      const message: BlockMessage = {
        payload: previewProps.blocks[0],
        type: 'block',
      };

      if (frameRef.current !== null && 'sendMessage' in frameRef.current) {
        const iframeElement = document.getElementById(
          frameId,
        ) as HTMLIFrameElement | null;

        if (iframeElement !== null && iframeElement.contentWindow) {
          iframeElement.contentWindow?.postMessage(
            JSON.stringify(message),
            '*',
          );
        }
      }
    },
    [frameId, optionLists],
  );

  const onMessage = useCallback(
    (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        try {
          const parsed = JSON.parse(event.data);
          if (parsed.type === 'ready') {
            onEmitBlockDetails(block);
            onReady && onReady();
          }
        } catch {}
      }
    },
    [block, onEmitBlockDetails, onReady],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [block, onMessage, onReady]);

  // Emit locale changes
  useEffect(() => {
    const iframeElement = document.getElementById(
      frameId,
    ) as HTMLIFrameElement | null;

    if (iframeElement !== null && iframeElement.contentWindow) {
      const message: LocaleChangeMessage = {
        payload: { locale: currentLocale },
        type: 'localeChange',
      };

      iframeElement.contentWindow?.postMessage(JSON.stringify(message), '*');
    }
  }, [currentLocale, frameId]);

  // Fire on each rerender to keep render cycles in sync
  // with AppShell
  onEmitBlockDetails(block);

  return (
    <IframeResizer
      forwardRef={frameRef}
      id={frameId}
      inPageLinks
      checkOrigin={false}
      heightCalculationMethod="bodyOffset"
      resizeFrom="child"
      src={APP_SHELL_COMPONENT_PREVIEW_URL}
      sizeWidth={false}
      minHeight={debouncedHeight}
      style={{
        width: '100%',
        border: 'none',
        height: debouncedHeight + 'px',
        transition: 'height 0.2s ease-out',
      }}
      loading="lazy"
      onResized={({ height }) => {
        /**
         * Because some component need to be unmounted in AppShell
         * We debounce the minHeight we apply to prevent any jumpiness
         * from the component having 0 height for a few milliseconds
         */
        if (height !== 0) {
          setDebouncedHeight(height);
        }
      }}
    />
  );
};
export default EmbeddedPreview;
