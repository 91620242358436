import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/molecule/Dropdown';
import Loading from '~/components/atom/Loading';
import {
  FlowV2_Action_Realworks_SendContactFragment,
  useGetAppStatusRealworksQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ErrorScreen from '~/components/page/ErrorScreen';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import InputLabelWithHelpLink from '~/components/molecule/InputLabelWithHelpLink';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Realworks_SendContactFragment;
};

const text = {
  dropdownLabel: 'Vestiging',
  helpLink: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
};

const RealworksSendContact: React.FCC<Props> = ({ action, onChange }) => {
  const [tokenContainerId, setTokenContainerId] = useState<
    FlowV2_Action_Realworks_SendContactFragment['tokenContainerId']
  >(action.tokenContainerId);
  const { id: accountId } = useCurrentAccount();

  const { data, loading } = useGetAppStatusRealworksQuery({
    variables: {
      accountId,
    },
  });

  const realworksStatus = data?.getAppStatusRealworks;

  useEffect(() => {
    onChange({ ...action, tokenContainerId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenContainerId]);

  if (!data || !realworksStatus) return <ErrorScreen />;
  if (loading) return <Loading />;

  const options = getActiveTokenOptions(realworksStatus);

  return (
    <Container>
      <InputLabelWithHelpLink
        label={text.dropdownLabel}
        help={{ link: text.helpLink }}
      />
      <Dropdown
        options={options}
        onChange={e => setTokenContainerId(e.option.payload)}
        selectedOptionIdx={options.findIndex(
          opt => opt.payload == tokenContainerId,
        )}
        dataTestId={TEST_ID.TOKEN_CONTAINER_DROPDOWN}
      />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxs')} 0;
  `,
);

export default RealworksSendContact;
