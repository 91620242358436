import React, { useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import { Props as HidableListProps } from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { FlowAction, useGetZapierTriggerLazyQuery } from '~/graphql/types';
import getCardHeading from '../../../../utils/getCardHeading';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { Heading5, Variant } from '~/components/atom/Typography';
import Variable from '../TemplateString/components/Variable';
import getVisibleAndCollapsedFields from './utils/getVisibleAndCollapsedFields';
import { ClientFlowActionZapierTrigger } from '~/graphql/types.client';
import useApp from '~/hooks/useApp';
import { useSetRecoilState } from 'recoil';
import { actionsSelector } from '~/components/page/Automation/v2/state/actions';
import removeObsoleteMappings from './utils/removeObsoleteMappings';

export type Props = Omit<
  CardProps<ClientFlowActionZapierTrigger>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const text = {
  outgoingLink: 'Uitgaande Koppeling',
  fields: 'Velden',
};

const ZapierTriggerCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const account = useCurrentAccount();
    const { zapierTriggerId, mappings } = data.action;
    const [getZapierTrigger, { data: zapierTriggerData }] =
      useGetZapierTriggerLazyQuery({
        variables: {
          accountId: account.id,
          id: zapierTriggerId,
        },
      });

    const setActions = useSetRecoilState(
      actionsSelector({
        accountId: account.id,
        flowBlueprintId: data.action.flowBlueprintId,
      }),
    );

    const { app } = useApp('AppStatus_Zapier');

    useEffect(() => {
      if (zapierTriggerId) {
        void getZapierTrigger({
          variables: {
            accountId: account.id,
            id: zapierTriggerId,
          },
        });
      }
    }, [zapierTriggerId, account.id, getZapierTrigger]);

    const triggerLabel = zapierTriggerData?.getZapierTrigger?.name;
    const fields = zapierTriggerData?.getZapierTrigger?.fields;
    const { visibleFields, collapsedFields } = getVisibleAndCollapsedFields({
      mappings,
      fields,
    });

    /**
     * Removes outdated mappings when a Zapier field's key has changed after
     * being mapped in the action. This ensures the flow can be saved.
     */
    useEffect(() => {
      if (!fields) return;

      const { outdatedMappingsIds, updatedAction } = removeObsoleteMappings({
        action: data.action,
        fields,
      });

      if (!isEmpty(outdatedMappingsIds)) {
        setActions(actions => {
          const mutableActions = [...actions];
          const subjectIndex = mutableActions.findIndex(
            action => action.id === data.action.id,
          );

          if (subjectIndex !== -1) {
            mutableActions[subjectIndex] = updatedAction;
          }

          return mutableActions;
        });
      }
    }, [data.action, fields, setActions]);

    const zapierCardData = {
      ...data,
      action: {
        ...data.action,
        appSlug: app?.slug ? `/-/apps/setup/${app.slug}` : null,
      },
    };
    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.ZapierTrigger}
          heading={getCardHeading(FlowAction.ZapierTrigger)}
          selected={selected}
          disabled={disabled}
          data={zapierCardData}
        >
          <Heading>
            <Heading5 variant={Variant.primary} inline>
              {text.outgoingLink}:{' '}
            </Heading5>
            {isNil(triggerLabel) || isEmpty(triggerLabel) ? '-' : triggerLabel}
          </Heading>
          <Heading5 variant={Variant.primary}>{text.fields}</Heading5>

          {!isEmpty(visibleFields) &&
            visibleFields.map(({ key, label }) => (
              <>
                <FieldContainer key={key}>
                  <FieldLabel>{label}</FieldLabel>
                  <Variable
                    mappings={mappings}
                    id={key}
                    actionId={data.action.id}
                  />
                </FieldContainer>
              </>
            ))}
          {!isEmpty(collapsedFields) && (
            <FieldContainer>{`+ ${collapsedFields.length} meer`}</FieldContainer>
          )}
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const FieldContainer = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxs')};
    margin-top: ${theme.space('base')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
  `,
);

const FieldLabel = styled.div(
  ({ theme }) => css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: ${theme.space('xxs')};
    font-weight: ${theme.fw('semiBold')};
  `,
);

const Heading = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default ZapierTriggerCard;
