import { isNil } from 'ramda';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Heading5, Variant } from '~/components/atom/Typography';
import getCardHeading from '../../../../../utils/getCardHeading';
import { Issue as IssueProps } from '../../../../../../../state/flowIssues';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import interactions from '~/components/page/Automation/v2/state/interactions';
import { actionById } from '~/components/page/Automation/v2/state/actions';
import { linkStyles } from '~/components/molecule/Link';
import TEST_ID from './index.testid';
import usePanToActionId from '../../../../../hooks/usePanToActionId';
import { navigate } from '@gatsbyjs/reach-router';

export type Props = {
  dataTestId?: string;
} & IssueProps;

const Issue: React.FCC<Props> = ({
  dataTestId,
  level,
  message,
  type,
  ...rest
}) => {
  const actionId = 'actionId' in rest ? rest.actionId : null;
  const actionType = 'actionType' in rest ? rest.actionType : null;

  const panToAction = usePanToActionId(actionId);
  const action = useRecoilValue(actionById(actionId));
  const setInteraction = useSetRecoilState(interactions);
  const heading = level === 'error' ? 'Foutmelding' : 'Waarschuwing';

  const onFocusAction = useCallback(() => {
    panToAction();
  }, [panToAction]);

  return (
    <Container $issue={level} data-testid={dataTestId} onClick={onFocusAction}>
      {!isNil(actionType) && (
        <CardHeading>{getCardHeading(actionType)}</CardHeading>
      )}
      <MessageContainer>
        <Heading5 inline variant={Variant.primary}>
          {heading}:{' '}
        </Heading5>
        <TextContainer>{message}</TextContainer>
        {!isNil(actionId) && type !== 'UnavailableActionIssue' && (
          <OpenActionButton
            data-testid={TEST_ID.GO_TO_ACTION_WITH_ISSUE}
            onClick={() => {
              if (action) {
                setInteraction({
                  type: 'updateAction',
                  action,
                });
              }
            }}
          >
            (aanpassen)
          </OpenActionButton>
        )}
        {type === 'UnavailableActionIssue' &&
          'pathToApp' in rest &&
          rest.pathToApp !== null && (
            <OpenActionButton
              data-testid={TEST_ID.GO_TO_ACTION_WITH_ISSUE}
              onClick={() => {
                if (rest.pathToApp !== null) {
                  void navigate(rest.pathToApp);
                }
              }}
            >
              (app toevoegen)
            </OpenActionButton>
          )}
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div<{
  $issue: IssueProps['level'];
}>(
  ({ theme, $issue }) => css`
    background: ${$issue === 'error'
      ? theme.color('danger', 'translucent')
      : theme.color('warning', 'light')};
    padding: ${theme.spacing('m')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    cursor: pointer;

    &:hover {
      border-color: ${theme.color('danger')};
    }
  `,
);

const MessageContainer = styled.div<{}>`
  display: inline;
`;

const CardHeading = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('xxs')};
    font-weight: ${theme.fw('semiBold')};
    padding-bottom: ${theme.spacing('xxxs')};
  `,
);

const OpenActionButton = styled.span`
  ${linkStyles}
`;

const TextContainer = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `}
`;

export default Issue;
