import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { linkStyles } from '~/components/molecule/Link';
import { Heading5, Variant } from '~/components/atom/Typography';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import type { Issue } from '../../../../../state/flowIssues';

type IssuesExceptSettings = Exclude<Issue, { type: 'SettingsIssue' }>;

export type Props = {
  dataTestId?: string;
  type: Issue['type'];
  level: IssuesExceptSettings['level'];
  actionType?: IssuesExceptSettings['actionType'];
  message: Issue['message'];
  actionId?: IssuesExceptSettings['actionId'];
  margin?: Array<SystemSize | null>;
};

const IssueInCard: React.FCC<Props> = ({
  dataTestId,
  level,
  type,
  message,
  actionId,
  margin = [],
  ...rest
}) => {
  if (type === 'SettingsIssue') return null;

  const heading = level === 'error' ? 'Foutmelding' : 'Waarschuwing';
  return (
    <Container
      issue={level}
      data-testid={dataTestId}
      {...rest}
      $margin={margin}
    >
      <Heading5 inline variant={Variant.primary}>
        {heading}:{' '}
      </Heading5>
      {message}{' '}
      {!isNil(actionId) && (
        <UpdateLink onClick={e => e.preventDefault()} href={`#${actionId}`}>
          (aanpassen)
        </UpdateLink>
      )}
    </Container>
  );
};

const Container = styled.div<{
  issue: IssuesExceptSettings['level'];
  $margin?: Props['margin'];
}>(({ theme, issue, $margin = [] }) => {
  const textColor =
    issue === 'error' ? theme.color('white') : theme.color('text');
  return css`
    background: ${issue === 'error'
      ? theme.color('danger')
      : theme.color('warning')};
    padding: ${theme.spacing('xxs')} ${theme.spacing('s')};
    border-radius: ${theme.getTokens().border.radius.s};
    color: ${textColor};
    margin: ${arrayToCss($margin, theme)};
    h4 {
      color: ${textColor};
    }
  `;
});

const UpdateLink = styled.a(
  ({ theme }) => css`
    ${linkStyles}
    color: ${theme.color('danger', 'text')};
    font-weight: ${theme.fw('semiBold')};

    &:hover {
      color: ${theme.color('danger', 'text')};
    }
  `,
);

export default IssueInCard;
