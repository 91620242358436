import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import type { OptionOf } from '~/components/molecule/Dropdown';
import { FlowAction, FlowV2_Update_Metadata } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import interactions, {
  Interaction,
} from '~/components/page/Automation/v2/state/interactions';

import type { Dialog } from '~/components/page/Automation/v2/state/dialogs';
import type { PathType } from '../../../IfElseCard/types';
import { initialFlowState } from '~/components/page/Automation/v2/state';
import { actionsSelector } from '~/components/page/Automation/v2/state/actions';
import metadata from '~/components/page/Automation/v2/state/metadata';
import dialogs from '~/components/page/Automation/v2/state/dialogs';
import lastDeletedActionNextChildId from '~/components/page/Automation/v2/state/lastDeletedActionNextChildId';
import useBuilderContext from '../../../../../../hooks/useBuilderContext';
import onDeleteActionIfElse from '../../utils/onDeleteActionIfElse';
import onDeleteAction from '../../utils/onDeleteAction';

type ActionArgs<ActionT> = {
  action: ActionT;
  actions: Array<ClientFlowAction>;
  setActions: SetterOrUpdater<Array<ClientFlowAction>>;
  setInteraction: SetterOrUpdater<Interaction | null>;
  setDialog: SetterOrUpdater<Dialog | null>;
  isExistingAction: boolean;
  setLastDeletedActionNextChildId: (id: string | null) => void;
  setFlowMetadata: SetterOrUpdater<Array<FlowV2_Update_Metadata>>;
  initialActions?: Array<ClientFlowAction>;
  initialFlowEnabled?: boolean;
  options?: {
    ifElseOptions?: {
      pathType: PathType | null;
    };
  };
};

export type OptionsArgs = ActionArgs<ClientFlowAction> & {
  isExistingAction?: boolean;
};
/**
 * Returns the dropdown options for a given actionType
 * @param {OptionsArgs} args - Args needed to perform the dropdown actions
 * keywords: dropdown options, options, card
 */
const useGetCardOptions = ({
  action,
}: {
  action: ClientFlowAction;
}): Array<OptionOf<(action: ClientFlowAction) => void>> => {
  const initialFlow = useRecoilValue(initialFlowState);
  const { accountId, flowBlueprintId } = useBuilderContext();

  const [actions, setActions] = useRecoilState(
    actionsSelector({
      accountId,
      flowBlueprintId,
    }),
  );
  const setInteraction = useSetRecoilState(interactions);
  const setFlowMetadata = useSetRecoilState(metadata);
  const setDialog = useSetRecoilState(dialogs);
  const setLastDeletedActionNextChildId = useSetRecoilState(
    lastDeletedActionNextChildId,
  );

  const isExistingAction =
    initialFlow?.actions.findIndex(action => action.id === action.id) !== -1;

  const args = {
    actions,
    setActions,
    setInteraction,
    setDialog,
    setFlowMetadata,
    setLastDeletedActionNextChildId: id => setLastDeletedActionNextChildId(id),
    initialFlowEnabled: initialFlow?.enabled,
  };

  switch (action.__typename) {
    case 'FlowV2_Action_Start':
      return [];
    case 'FlowV2_Action_Wait':
    case 'FlowV2_Action_Realworks_SendContact':
    case 'FlowV2_Action_SendEmail_Plain':
    case 'FlowV2_Action_SendNotification':
    case 'FlowV2_Action_Zapier_Trigger':
    case 'FlowV2_Action_Task_Create':
    case 'FlowV2_Action_Contact_Details':
    case 'FlowV2_Action_Contact_AddTag':
    case 'FlowV2_Action_Contact_DeleteTag':
    case 'FlowV2_Action_Contact_Assign':
      const optionArgs = {
        action,
        isExistingAction,
        ...args,
      };
      const options = [getCopyOption(optionArgs), getDeleteOption(optionArgs)];

      return options;

    case 'FlowV2_Action_IfElse':
      return [
        getCopyOption({ action, isExistingAction, ...args }),
        getDeleteOption({ action, isExistingAction, ...args }),
      ];

    default:
      return [];
  }
};

const deleteAction = ({
  actions,
  action,
  setActions,
  setLastDeletedActionNextChildId,
  setDialog,
  setFlowMetadata,
  initialActions,
  initialFlowEnabled,
  options,
}: ActionArgs<ClientFlowAction>) =>
  action.__typename === 'FlowV2_Action_IfElse'
    ? onDeleteActionIfElse({
        actions,
        subjectAction: action,
        setActions,
        setLastDeletedActionNextChildId,
        setDialog,
        setFlowMetadata,
        initialActions: initialActions ?? [],
        initialFlowEnabled: initialFlowEnabled ?? false,
        options: options?.ifElseOptions,
      })
    : onDeleteAction(action, setActions, setLastDeletedActionNextChildId);

const getDeleteOption = (
  args: ActionArgs<ClientFlowAction>,
): OptionOf<(action: ClientFlowAction) => void> => ({
  key: 'delete',
  label: 'Verwijderen',
  icon: { name: 'trashcan' },
  type: 'DANGER',
  payload: () => {
    if (
      args.action.actionType === FlowAction.Wait &&
      args.isExistingAction &&
      args.initialFlowEnabled
    ) {
      return args.setInteraction({
        type: 'setMetadataForWaitAction',
        action: args.action,
        onConfirm: () => deleteAction(args),
        onCancel: () => {},
      });
    }

    if (args.action.actionType === FlowAction.IfElse && args.isExistingAction) {
      return args.setDialog({
        type: 'dialogDeleteIfElseAction',
        action: args.action,
        onConfirm: pathType => {
          deleteAction({ ...args, options: { ifElseOptions: { pathType } } });
          args.setDialog(null);
        },
        onCancel: () => args.setDialog(null),
      });
    }

    return deleteAction(args);
  },
});

const getCopyOption = (
  args: ActionArgs<
    Exclude<ClientFlowAction, { __typename: 'FlowV2_Action_Start' }>
  >,
): OptionOf<(action: ClientFlowAction) => void> => ({
  key: 'copy',
  label: 'Kopiëren',
  icon: { name: 'copy' },
  payload: () => {
    args.setInteraction({
      type: 'copyAction',
      copiedAction: args.action,
      actionsBeforeCopy: args.actions,
    });
  },
});

export default useGetCardOptions;
